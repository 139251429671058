@media (max-width: 2000px) {
  .plan-btn {
    width: 20rem;
  }
  .logo {
    height: 6rem;
  }
  .container-how {
    margin: 0 auto;
    max-width: 130rem;
    padding: 0 3.2rem;
  }
}

@media (max-width: 1440px) {
  .spacer {
    column-gap: 2rem;
  }
  .section-pricing {
    padding-top: 2rem;
  }
  .plan-head {
    padding: 2.4rem !important;
  }
}

@media (max-width: 1300px) {
  .pricing-plan--advanced::after {
    top: 7%;
    right: -26%;
  }
  /* .container-how {
    margin: 0 auto;
    max-width: 122rem;
  } */
}

@media (max-width: 1273px) {
  .heading-primary {
    font-size: 4.9rem;
  }
  .main-nav-list {
    gap: 2.2rem;
  }

  .spacerserv {
    column-gap: 4rem;
  }
  .plan-btn {
    width: 18rem;
    padding: 1.4rem 3.2rem !important;
  }
  /* .container-how {
    margin: 0 auto;
    max-width: 119rem;
  } */
}

@media (max-width: 1260px) {
  .heading-primary {
    font-size: 4rem;
    line-height: 4.2rem;
  }
  .frame {
    height: 500px;
  }
  .spacer {
    column-gap: 2rem;
  }
  .pricing-plan--professional,
  .pricing-plan--advanced,
  .pricing-plan--basic {
    padding: 3.2rem 1.4rem;
  }
  .pricing-plan--advanced::after {
    top: 6%;
    right: -27%;
    font-size: 1.2rem;
  }
}

@media (max-width: 1192px) {
  .container-how {
    margin: 0 auto;
    max-width: 120rem;
    padding: 0 3.2rem;
  }
}
@media (max-width: 1157px) {
  .pricing-plan--advanced::after {
    top: 5%;
    right: -30%;
  }
}

@media (max-width: 1100px) {
  .main-nav-list {
    gap: 2.2rem;
  }
  .hero-description {
    font-size: 1.8rem;
  }
  .logos img {
    height: 4.5rem;
  }

  .heading-primary {
    font-size: 3.9rem;
    line-height: 4rem;
  }

  .spacer {
    column-gap: 4rem;
  }

  .list-item span {
    font-size: 1.5rem;
  }

  /* .pricing-plan--professional,
  .pricing-plan--advanced,
  .pricing-plan--basic {
    padding: 2.4rem;
  } */
  /* .pricing-plan--basic {
    gap: 0px;
  } */

  .spacerserv {
    column-gap: 3.5rem;
  }
  .service-content {
    padding: 3.2rem 2.8rem 4.8rem 2.8rem;
  }
  .cta-text-box {
    padding: 4.8rem 2.4rem 6.4rem 2.4rem;
  }
  .cta-form input,
  .cta-form select {
    padding: 1rem;
    font-size: 1.5rem;
  }
  .stephead {
    margin-bottom: 2.5rem;
  }
  .low-gap {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5rem !important;
  }
  .pricing-plan--advanced::after {
    top: 5%;
    right: -33%;
  }
  .sizer {
    font-size: 4rem;
  }
}

@media (max-width: 1045px) {
  .header {
    transition: all 1s;
  }
  .main-nav-list {
    display: none;
  }
  .menuicon {
    display: block;
    height: 4rem;
  }
  .heading-primary {
    line-height: 4rem;
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }
  .ressub {
    font-size: 1.7rem;
  }
  .reshead {
    font-size: 3.7rem;
  }
  .plan-btn {
    width: 18rem;
    font-size: 1.5rem;
    padding: 1.3rem 3.2rem !important;
  }
  .pricing-plan--advanced::after {
    top: 5%;
    right: -36%;
  }
}

@media (max-width: 1020px) {
  .pricing-plan--advanced::after {
    top: 4%;
    right: -37%;
    font-size: 1.1rem;
  }
}

@media (max-width: 960px) {
  .heading-primary {
    font-size: 3.4rem;
    margin-bottom: 2.2rem;
  }
  .hero-description {
    font-size: 1.6rem;
  }
  .padder {
    padding-right: 1.5rem;
    column-gap: 3.2rem;
  }
  .iframe {
    width: 500px;
    height: 500px;
  }

  .sizer {
    font-size: 3.4rem;
    margin-bottom: 5.6rem;
  }
  .marginer {
    margin-bottom: 6rem !important;
  }
  .section-how {
    padding-bottom: 4rem;
  }
  .section-reviews {
    padding-bottom: 1rem;
  }

  .section-pricing {
    padding-top: 9.6rem;
  }
  .service-content {
    padding: 3.2rem 1.5rem 2.8rem 1.5rem;
  }
  .service-title {
    font-size: 1.8rem;
  }
  .service-attribute {
    font-size: 1.5rem;
  }
  .section-services {
    padding-bottom: 4rem;
  }
  .cta-form {
    column-gap: 1.7rem;
  }
  .step-description {
    font-size: 1.7rem;
    line-height: 1.5;
  }
  .stephead {
    margin-bottom: 2rem !important;
  }
  .cta-form {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr 1fr !important;
  }
  .cta .heading-secondary {
    margin-bottom: 0.2rem;
  }
  .cta-text {
    margin-bottom: 2.8rem;
  }
  .cont-plan {
    gap: 1.8rem;
  }
  .plan-price {
    font-size: 5rem;
  }
  .plan-btn {
    width: 16rem;
    font-size: 1.5rem;
    padding: 1.3rem 2.2rem !important;
  }
  .pricing-plan--advanced::after {
    top: 4%;
    right: -40%;
  }
}

@media (max-width: 920px) {
  .hero {
    gap: 0px;
  }
  .spacerserv {
    column-gap: 2.5rem;
  }
  .logos img {
    height: 4rem;
  }
  .step-description {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .plan-btn {
    font-size: 1.8rem !important;
    padding: 1.3rem 2.6rem !important;
  }
  .pricing-plan--advanced::after {
    display: none;
  }
}
@media (max-width: 875px) {
  .plan-btn {
    width: 14rem;
  }
}
@media (max-width: 820px) {
  .heading-primary {
    font-size: 3rem;
    margin-bottom: 1.1rem;
  }
  .btn--sizer {
    padding: 1.6rem 2.5rem !important;
  }
  .logos {
    justify-content: space-evenly;
  }
  .hero-description {
    font-size: 1.5rem;
  }
  .spacerserv {
    column-gap: 2rem;
    padding: 0 2rem;
  }
  .low-gap {
    column-gap: 4.4rem;
  }
  label {
    font-size: 1.4rem !important;
  }

  .marginer {
    margin-bottom: 5rem !important;
    column-gap: 5.4rem;
  }
  .step-description {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .logos img {
    height: 3.5rem;
  }
  .review-text {
    line-height: 1.4;
  }
  .btn--sizer {
    padding: 1.5rem 1.5rem !important;
    font-size: 1.8rem !important;
  }
  .plan-btn {
    padding: 1rem 2rem !important;
  }
  .cont-plan {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 2.5rem;
    padding: 3.2rem;
  }
  .pricing-plan--professional {
    justify-self: auto;
  }
  .plan-price {
    font-size: 4.5rem;
  }
  .plan-name {
    font-size: 1.8rem;
  }
  .pricing-plan--advanced::after {
    display: block;
    top: 7%;
    right: -23%;
    font-size: 1.6rem;
  }
  .sizer {
    font-size: 3rem;
  }
}

@media (max-width: 745px) {
  .sizer {
    font-size: 3rem;
  }
  .btn--sizer {
    padding: 1.3rem 1.5rem !important;
    font-size: 1.8rem !important;
  }
}

@media (max-width: 725px) {
  .heading-primary {
    font-size: 2.7rem;
    line-height: 1.2;
  }
  .hero-description {
    font-size: 1.3rem;
  }
  .logos img {
    height: 3.2rem;
  }
  .low-gap {
    column-gap: 2.4rem;
  }
  .iframe {
    width: 400px !important;
  }
  .stephead {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .btn--sizer {
    padding: 1.2rem 1.5rem !important;
  }
  .logos img {
    height: 3rem;
  }
  .section-how {
    padding-top: 4rem;
  }
  .cta-text {
    font-size: 1.6rem;
  }
  .btn--sizer {
    padding: 1.3rem 1.5rem !important;
    font-size: 1.8rem !important;
  }
  .pricing-plan--advanced::after {
    top: 6%;
    right: -28%;
  }
  .sizer {
    font-size: 2.7rem;
  }
}

@media (max-width: 660px) {
  .delivered-text {
    font-size: 2.3rem;
  }
  .heading-primary {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .btn--sizer {
    padding: 1rem 1.5rem !important;
  }
  .sizer {
    font-size: 2.55rem;
  }
  .secspace {
    font-size: 4rem;
  }
  .ressub {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
  }
  .pricing-plan--advanced::after {
    top: 6%;
    right: -29%;
    font-size: 1.3rem;
  }
}

@media (max-width: 630px) {
  .iframe {
    width: 350px !important;
  }
  .spacer {
    column-gap: 2rem;
  }
  .sizer {
    font-size: 2.45rem;
  }
}

/* @media (max-width: 575px) {
  .iframe {
    width: 300px !important;
  }
  .spacer {
    display: none;
  }
  .logos img {
    height: 2.8rem;
  }
} */

/* For Mobile devices */
@media (max-width: 600px) {
  .hero {
    grid-template-columns: none;
    padding: 0 2.2rem;
  }
  .lcont {
    padding: 0 !important;
  }
  .logos img {
    height: 2.5rem;
  }
  .marginer {
    grid-template-columns: none;
    gap: 4rem;
  }
  .textbelow {
    grid-row: 2;
  }
  .textflex {
    display: flex;
    gap: 0.5rem;
  }
  .step-number {
    font-size: 2.5rem;
  }
  .stephead {
    font-size: 2.5rem;
  }
  .low-gap {
    column-gap: 2.4rem;
    grid-template-columns: none;
  }
  .spacerserv {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 4rem;
  }
  .spacer {
    display: none;
  }
  .reshead {
    margin-bottom: 2rem !important;
  }
  .iframe {
    width: 100% !important;
  }
  .cta {
    grid-template-columns: none;
    grid-template-rows: 225px 1fr;
  }
  .cta-img-box {
    grid-row: 1;
    background-image: none;
    background-image: url("../img/contact-us-mobile.webp");
    background-position: 50% 33%;
  }
  .header {
    /* background-color: rgb(251, 255, 0) !important; */
    padding: 0rem 1.5rem;
    height: 8.7rem;
  }
  .logo {
    height: 5.5rem;
  }
  .menuicon {
    height: 5.5rem;
  }
  .decpad {
    padding: 0 1.6rem;
  }
  .container {
    padding: 0 1.6rem !important;
  }
  .padder {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.6rem !important;
    padding-bottom: 5.2rem !important;
  }
  .cta-text-box {
    padding: 2.8rem 2.4rem 6.4rem 2.4rem;
  }
  .cont-plan {
    padding: 2rem;
    column-gap: 1.2rem;
  }
  .pricing-plan--advanced::after {
    top: 5%;
    right: -31%;
    font-size: 1.2rem;
  }
  .container-how {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0px 1.8rem;
  }
  .sizer {
    font-size: 3.4rem;
  }
}

@media (max-width: 550px) {
  .pricing-plan--advanced::after {
    top: 5%;
    right: -33%;
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .cont-plan {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding: 3.6rem;
    row-gap: 2rem;
  }
  .plan-price {
    font-size: 5.6rem;
  }
  .plan-name {
    font-size: 2.6rem;
  }
  .plan-btn {
    font-size: 2.8rem !important;
    padding: 1.8rem 2rem !important;
    width: 19rem;
  }

  /* Changing button position */
  .plan-sign-up {
    order: 3;
  }

  .list {
    order: 2;
  }
  .plan-header {
    order: 1;
  }
  .pricing-plan--advanced::after {
    top: 7%;
    right: -19%;
    font-size: 1.5rem;
  }
  .logo {
    height: 5rem;
  }
}

@media (max-width: 470px) {
  .logos img {
    height: 2.3rem;
    width: 10rem;
  }
  .plan-btn {
    font-size: 2.5rem !important;
    padding: 1rem 2rem !important;
  }
  .pricing-plan--advanced::after {
    top: 7%;
    right: -21%;
  }
  .logo {
    height: 4.8rem;
  }
}
@media (max-width: 420px) {
  .logos img {
    height: 2.2rem;
    width: 10rem;
  }
  .pricing-plan--advanced::after {
    right: -24%;
  }
  .logo {
    height: 4.6rem;
  }
  .sizer {
    font-size: 3rem;
  }
}
@media (max-width: 405px) {
  .logos img {
    /* height: 2.2rem; */
    width: 100%;
  }
  .pricing-plan--advanced::after {
    top: 6%;
    right: -24%;
    font-size: 1.3rem;
  }
}

@media (max-width: 380px) {
  .logo {
    height: 4.5rem;
  }
  .sizer {
    font-size: 2.8rem;
  }
}
@media (max-width: 360px) {
  .logo {
    height: 4rem;
  }
  .menuicon {
    height: 3.8rem;
  }
  .pricing-plan--advanced::after {
    top: 5%;
    right: -28%;
  }
  .sizer {
    font-size: 2.6rem;
  }
}
@media (max-width: 350px) {
  .menuicon {
    height: 3.6rem;
  }
  .pricing-plan--advanced::after {
    top: 5%;
    right: -31%;
  }
}

@media (max-width: 335px) {
  .pricing-plan--advanced::after {
    top: 5%;
    right: -31%;
  }
  .sizer {
    font-size: 2.4rem;
  }
}
