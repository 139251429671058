@media (width <= 2000px) {
  .plan-btn {
    width: 20rem;
  }

  .logo {
    height: 6rem;
  }

  .container-how {
    max-width: 130rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }
}

@media (width <= 1440px) {
  .spacer {
    column-gap: 2rem;
  }

  .section-pricing {
    padding-top: 2rem;
  }

  .plan-head {
    padding: 2.4rem !important;
  }
}

@media (width <= 1300px) {
  .pricing-plan--advanced:after {
    top: 7%;
    right: -26%;
  }
}

@media (width <= 1273px) {
  .heading-primary {
    font-size: 4.9rem;
  }

  .main-nav-list {
    gap: 2.2rem;
  }

  .spacerserv {
    column-gap: 4rem;
  }

  .plan-btn {
    width: 18rem;
    padding: 1.4rem 3.2rem !important;
  }
}

@media (width <= 1260px) {
  .heading-primary {
    font-size: 4rem;
    line-height: 4.2rem;
  }

  .frame {
    height: 500px;
  }

  .spacer {
    column-gap: 2rem;
  }

  .pricing-plan--professional, .pricing-plan--advanced, .pricing-plan--basic {
    padding: 3.2rem 1.4rem;
  }

  .pricing-plan--advanced:after {
    font-size: 1.2rem;
    top: 6%;
    right: -27%;
  }
}

@media (width <= 1192px) {
  .container-how {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }
}

@media (width <= 1157px) {
  .pricing-plan--advanced:after {
    top: 5%;
    right: -30%;
  }
}

@media (width <= 1100px) {
  .main-nav-list {
    gap: 2.2rem;
  }

  .hero-description {
    font-size: 1.8rem;
  }

  .logos img {
    height: 4.5rem;
  }

  .heading-primary {
    font-size: 3.9rem;
    line-height: 4rem;
  }

  .spacer {
    column-gap: 4rem;
  }

  .list-item span {
    font-size: 1.5rem;
  }

  .spacerserv {
    column-gap: 3.5rem;
  }

  .service-content {
    padding: 3.2rem 2.8rem 4.8rem;
  }

  .cta-text-box {
    padding: 4.8rem 2.4rem 6.4rem;
  }

  .cta-form input, .cta-form select {
    padding: 1rem;
    font-size: 1.5rem;
  }

  .stephead {
    margin-bottom: 2.5rem;
  }

  .low-gap {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: none;
    gap: 5rem !important;
  }

  .pricing-plan--advanced:after {
    top: 5%;
    right: -33%;
  }

  .sizer {
    font-size: 4rem;
  }
}

@media (width <= 1045px) {
  .header {
    transition: all 1s;
  }

  .main-nav-list {
    display: none;
  }

  .menuicon {
    height: 4rem;
    display: block;
  }

  .heading-primary {
    margin-bottom: 2rem;
    font-size: 3.6rem;
    line-height: 4rem;
  }

  .ressub {
    font-size: 1.7rem;
  }

  .reshead {
    font-size: 3.7rem;
  }

  .plan-btn {
    width: 18rem;
    font-size: 1.5rem;
    padding: 1.3rem 3.2rem !important;
  }

  .pricing-plan--advanced:after {
    top: 5%;
    right: -36%;
  }
}

@media (width <= 1020px) {
  .pricing-plan--advanced:after {
    font-size: 1.1rem;
    top: 4%;
    right: -37%;
  }
}

@media (width <= 960px) {
  .heading-primary {
    margin-bottom: 2.2rem;
    font-size: 3.4rem;
  }

  .hero-description {
    font-size: 1.6rem;
  }

  .padder {
    column-gap: 3.2rem;
    padding-right: 1.5rem;
  }

  .iframe {
    width: 500px;
    height: 500px;
  }

  .sizer {
    margin-bottom: 5.6rem;
    font-size: 3.4rem;
  }

  .marginer {
    margin-bottom: 6rem !important;
  }

  .section-how {
    padding-bottom: 4rem;
  }

  .section-reviews {
    padding-bottom: 1rem;
  }

  .section-pricing {
    padding-top: 9.6rem;
  }

  .service-content {
    padding: 3.2rem 1.5rem 2.8rem;
  }

  .service-title {
    font-size: 1.8rem;
  }

  .service-attribute {
    font-size: 1.5rem;
  }

  .section-services {
    padding-bottom: 4rem;
  }

  .cta-form {
    column-gap: 1.7rem;
  }

  .step-description {
    font-size: 1.7rem;
    line-height: 1.5;
  }

  .stephead {
    margin-bottom: 2rem !important;
  }

  .cta-form {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr 1fr !important;
  }

  .cta .heading-secondary {
    margin-bottom: .2rem;
  }

  .cta-text {
    margin-bottom: 2.8rem;
  }

  .cont-plan {
    gap: 1.8rem;
  }

  .plan-price {
    font-size: 5rem;
  }

  .plan-btn {
    width: 16rem;
    font-size: 1.5rem;
    padding: 1.3rem 2.2rem !important;
  }

  .pricing-plan--advanced:after {
    top: 4%;
    right: -40%;
  }
}

@media (width <= 920px) {
  .hero {
    gap: 0;
  }

  .spacerserv {
    column-gap: 2.5rem;
  }

  .logos img {
    height: 4rem;
  }

  .step-description {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .plan-btn {
    padding: 1.3rem 2.6rem !important;
    font-size: 1.8rem !important;
  }

  .pricing-plan--advanced:after {
    display: none;
  }
}

@media (width <= 875px) {
  .plan-btn {
    width: 14rem;
  }
}

@media (width <= 820px) {
  .heading-primary {
    margin-bottom: 1.1rem;
    font-size: 3rem;
  }

  .btn--sizer {
    padding: 1.6rem 2.5rem !important;
  }

  .logos {
    justify-content: space-evenly;
  }

  .hero-description {
    font-size: 1.5rem;
  }

  .spacerserv {
    column-gap: 2rem;
    padding: 0 2rem;
  }

  .low-gap {
    column-gap: 4.4rem;
  }

  label {
    font-size: 1.4rem !important;
  }

  .marginer {
    column-gap: 5.4rem;
    margin-bottom: 5rem !important;
  }

  .step-description {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .logos img {
    height: 3.5rem;
  }

  .review-text {
    line-height: 1.4;
  }

  .btn--sizer {
    padding: 1.5rem !important;
    font-size: 1.8rem !important;
  }

  .plan-btn {
    padding: 1rem 2rem !important;
  }

  .cont-plan {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 2.5rem;
    padding: 3.2rem;
    display: grid;
  }

  .pricing-plan--professional {
    justify-self: auto;
  }

  .plan-price {
    font-size: 4.5rem;
  }

  .plan-name {
    font-size: 1.8rem;
  }

  .pricing-plan--advanced:after {
    font-size: 1.6rem;
    display: block;
    top: 7%;
    right: -23%;
  }

  .sizer {
    font-size: 3rem;
  }
}

@media (width <= 745px) {
  .sizer {
    font-size: 3rem;
  }

  .btn--sizer {
    padding: 1.3rem 1.5rem !important;
    font-size: 1.8rem !important;
  }
}

@media (width <= 725px) {
  .heading-primary {
    font-size: 2.7rem;
    line-height: 1.2;
  }

  .hero-description {
    font-size: 1.3rem;
  }

  .low-gap {
    column-gap: 2.4rem;
  }

  .iframe {
    width: 400px !important;
  }

  .stephead {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .btn--sizer {
    padding: 1.2rem 1.5rem !important;
  }

  .logos img {
    height: 3rem;
  }

  .section-how {
    padding-top: 4rem;
  }

  .cta-text {
    font-size: 1.6rem;
  }

  .btn--sizer {
    padding: 1.3rem 1.5rem !important;
    font-size: 1.8rem !important;
  }

  .pricing-plan--advanced:after {
    top: 6%;
    right: -28%;
  }

  .sizer {
    font-size: 2.7rem;
  }
}

@media (width <= 660px) {
  .delivered-text {
    font-size: 2.3rem;
  }

  .heading-primary {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .btn--sizer {
    padding: 1rem 1.5rem !important;
  }

  .sizer {
    font-size: 2.55rem;
  }

  .secspace {
    font-size: 4rem;
  }

  .ressub {
    margin-bottom: .8rem;
    font-size: 1.5rem;
  }

  .pricing-plan--advanced:after {
    font-size: 1.3rem;
    top: 6%;
    right: -29%;
  }
}

@media (width <= 630px) {
  .iframe {
    width: 350px !important;
  }

  .spacer {
    column-gap: 2rem;
  }

  .sizer {
    font-size: 2.45rem;
  }
}

@media (width <= 600px) {
  .hero {
    grid-template-columns: none;
    padding: 0 2.2rem;
  }

  .lcont {
    padding: 0 !important;
  }

  .logos img {
    height: 2.5rem;
  }

  .marginer {
    grid-template-columns: none;
    gap: 4rem;
  }

  .textbelow {
    grid-row: 2;
  }

  .textflex {
    gap: .5rem;
    display: flex;
  }

  .step-number, .stephead {
    font-size: 2.5rem;
  }

  .low-gap {
    grid-template-columns: none;
    column-gap: 2.4rem;
  }

  .spacerserv {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: none;
    gap: 4rem;
  }

  .spacer {
    display: none;
  }

  .reshead {
    margin-bottom: 2rem !important;
  }

  .iframe {
    width: 100% !important;
  }

  .cta {
    grid-template-rows: 225px 1fr;
    grid-template-columns: none;
  }

  .cta-img-box {
    background-image: url("contact-us-mobile.927fc0d6.webp");
    background-position: 50% 33%;
    grid-row: 1;
  }

  .header {
    height: 8.7rem;
    padding: 0 1.5rem;
  }

  .logo, .menuicon {
    height: 5.5rem;
  }

  .decpad {
    padding: 0 1.6rem;
  }

  .container {
    padding: 0 1.6rem !important;
  }

  .padder {
    flex-direction: column;
    gap: 2rem;
    display: flex;
    padding: 0 1.6rem 5.2rem !important;
  }

  .cta-text-box {
    padding: 2.8rem 2.4rem 6.4rem;
  }

  .cont-plan {
    column-gap: 1.2rem;
    padding: 2rem;
  }

  .pricing-plan--advanced:after {
    font-size: 1.2rem;
    top: 5%;
    right: -31%;
  }

  .container-how {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 1.8rem;
  }

  .sizer {
    font-size: 3.4rem;
  }
}

@media (width <= 550px) {
  .pricing-plan--advanced:after {
    font-size: 1rem;
    top: 5%;
    right: -33%;
  }
}

@media (width <= 500px) {
  .cont-plan {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: none;
    row-gap: 2rem;
    padding: 3.6rem;
  }

  .plan-price {
    font-size: 5.6rem;
  }

  .plan-name {
    font-size: 2.6rem;
  }

  .plan-btn {
    width: 19rem;
    padding: 1.8rem 2rem !important;
    font-size: 2.8rem !important;
  }

  .plan-sign-up {
    order: 3;
  }

  .list {
    order: 2;
  }

  .plan-header {
    order: 1;
  }

  .pricing-plan--advanced:after {
    font-size: 1.5rem;
    top: 7%;
    right: -19%;
  }

  .logo {
    height: 5rem;
  }
}

@media (width <= 470px) {
  .logos img {
    width: 10rem;
    height: 2.3rem;
  }

  .plan-btn {
    padding: 1rem 2rem !important;
    font-size: 2.5rem !important;
  }

  .pricing-plan--advanced:after {
    top: 7%;
    right: -21%;
  }

  .logo {
    height: 4.8rem;
  }
}

@media (width <= 420px) {
  .logos img {
    width: 10rem;
    height: 2.2rem;
  }

  .pricing-plan--advanced:after {
    right: -24%;
  }

  .logo {
    height: 4.6rem;
  }

  .sizer {
    font-size: 3rem;
  }
}

@media (width <= 405px) {
  .logos img {
    width: 100%;
  }

  .pricing-plan--advanced:after {
    font-size: 1.3rem;
    top: 6%;
    right: -24%;
  }
}

@media (width <= 380px) {
  .logo {
    height: 4.5rem;
  }

  .sizer {
    font-size: 2.8rem;
  }
}

@media (width <= 360px) {
  .logo {
    height: 4rem;
  }

  .menuicon {
    height: 3.8rem;
  }

  .pricing-plan--advanced:after {
    top: 5%;
    right: -28%;
  }

  .sizer {
    font-size: 2.6rem;
  }
}

@media (width <= 350px) {
  .menuicon {
    height: 3.6rem;
  }

  .pricing-plan--advanced:after {
    top: 5%;
    right: -31%;
  }
}

@media (width <= 335px) {
  .pricing-plan--advanced:after {
    top: 5%;
    right: -31%;
  }

  .sizer {
    font-size: 2.4rem;
  }
}

/*# sourceMappingURL=index.5af187ca.css.map */
